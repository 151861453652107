var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-my-page","size":"xl","hide-footer":true,"body-class":"p-0  vh-100","header-class":"p-0 d-block border-bottom-0","scrollable":"","centered":""},on:{"shown":_vm.showCb,"hide":_vm.hideCb},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"px-4 px-md-5 py-3 flex-end"},[_c('b-btn',{style:({ marginRight: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: ("url(" + (require('@/assets/img/common/ic-close.svg')) + ")"),
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[_c('div',{staticClass:"px-4 px-md-5"},[(_vm.itemObj)?[(['mycall'].includes(_vm.targetApiKey))?[_c('p',{staticClass:"gray-5 fs-20 mb-3"},[_vm._v(_vm._s(_vm.date))]),_c('p',{staticClass:"fs-20 mb-5",domProps:{"innerHTML":_vm._s(_vm.replaceBr(_vm.itemObj.ask))}}),_c('div',{staticClass:"p-4 mb-2 fs-20",style:({ backgroundColor: '#fafafa' })},[_c('span',{staticClass:"fw-7"},[_vm._v("콜센터")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.replaceBr(_vm.itemObj.answer))}})])]:(['healthcheck', 'psycheck'].includes(_vm.targetApiKey))?[_c('survey-result-head',_vm._b({},'survey-result-head',Object.assign({}, _vm.itemObj),false)),(['healthcheck'].includes(_vm.key))?_c('div',{staticClass:"border-top border-green",style:({ marginBottom: '6rem' })},_vm._l((_vm.itemObj.checkAgeResult),function(row,i){return _c('hc-collapse',_vm._b({key:i,staticClass:"border-bottom border-gray-5"},'hc-collapse',Object.assign({}, row,
              {idx: i}),false))}),1):_vm._e()]:_vm._e()]:_c('skeleton-survey-result-head')],2)])}
var staticRenderFns = []

export { render, staticRenderFns }