var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isScroll)?[_c('b-nav',{directives:[{name:"b-scrollspy",rawName:"v-b-scrollspy",value:({
        offset: this.offset,
        element: ("#" + (this.scrollspyId) + "___BV_modal_body_"),
        method: 'auto',
      }),expression:"{\n        offset: this.offset,\n        element: `#${this.scrollspyId}___BV_modal_body_`,\n        method: 'auto',\n      }"}],staticClass:"modal-tab-wrap border-bottom border-theme-color-1 px-4 px-md-5 d-block overflow-hidden"},[_c('tab-swiper',{ref:"modal-tab-swiper",staticClass:"tab",attrs:{"items":_vm.items,"options":{
          navigation: {
            nextEl: '.modal-tab-next',
            prevEl: '.modal-tab-prev',
          },
          spaceBetween: 85,
          breakpoints: {
            576: {
              spaceBetween: 50,
            },
            768: {
              spaceBetween: 60,
            },
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var tab = ref.tab;
        var idx = ref.idx;
return [_c('b-nav-item',{key:idx,staticClass:"tab-item gray-3 fs-20 fs-md-18 pb-2",attrs:{"href":("#modal-tab-" + idx)},on:{"click":function($event){$event.preventDefault();return _vm.tabBtnClickHandle(("#modal-tab-" + idx), _vm.modalBody)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])]}}],null,false,2450435718)})],1)]:[_c('b-nav',{staticClass:"modal-tab-wrap border-bottom border-theme-color-1 px-4 px-md-5 d-block"},[_c('tab-swiper',{ref:"modal-tab-swiper",staticClass:"tab",attrs:{"items":_vm.items,"options":{
          navigation: {
            nextEl: '.modal-tab-next',
            prevEl: '.modal-tab-prev',
          },
          spaceBetween: 85,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var tab = ref.tab;
        var idx = ref.idx;
return [_c('li',{class:[
            _vm.tagetTab === tab ? 'active' : '',
            'tab-item gray-3 fs-20 fs-md-18 pb-2' ]},[_c('a',{key:idx,attrs:{"href":("#modal-tab-" + idx)},on:{"click":function($event){$event.preventDefault();return _vm.handleClick(tab)}}},[_vm._v(" "+_vm._s(tab)+" ")])])]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }