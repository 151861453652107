var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-5",style:({ background: '#f6fbf4' })},[_c('b-container',[_c('p',{staticClass:"fw-7 text-center mb-4 px-3 fs-25"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"row flex-center"},[_c('tab-swiper',{staticClass:"b-btn-wrap",attrs:{"items":_vm.btnArr,"options":{
          // slidesOffsetBefore: 20,
          // slidesOffsetAfter: 20,
          spaceBetween: 20,
          breakpoints: {
            768: {
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
            },
            994: {
              spaceBetween: 15,
            },
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var tab = ref.tab;
return [_c('b-btn',{style:({ minWidth: _vm.calcHeight('120px') }),attrs:{"variant":"link w-100 green-btn-2 py-3 px-4 fs-20"},on:{"click":function($event){return _vm.$emit('handleClick', tab.id)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }