<template>
  <div>
    <!-- 메인 페이지 -->
    <div class="home" v-if="!['kyoboservices'].includes(type)">
      <!-- 메인 슬라이드 -->
      <section class="main-slide-section">
        <main-slide
          v-if="isArray($t('visual')).length"
          :slide="isArray($t('visual'))"
          :card="
            ['kyobohealthcare', 'kyobowomancare', 'kyobojuniorcare'].includes(
              type
            )
          "
        ></main-slide>
      </section>
      <!-- //메인 슬라이드 -->

      <!-- 건강체크 -->
      <section
        v-if="typeof $t('healthCheck') === 'object'"
        class="container mb-5"
      >
        <sub-slide
          v-if="isArray($t('healthCheck.items')).length"
          :slides="isArray($t('healthCheck.items'))"
          :title="$t('healthCheck.title')"
          :subTitle="$t('healthCheck.subTitle')"
          :options="{
            slidesPerView: 3,
            spaceBetween: 18,
            breakpoints: {
              400: {
                slidesPerView: 1.8,
                spaceBetween: 10,
              },
              574: {
                slidesPerView: 2.5,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2.7,
                spaceBetween: 18,
              },
              994: {
                slidesPerView: 3,
                spaceBetween: 18,
              },
            },
            navigation: {
              nextEl: '.sub-slide-next',
              prevEl: '.sub-slide-prev',
            },
          }"
          v-slot="{ slide, idx }"
        >
          <router-link
            :to="{ name: slide.name, params: { key: slide.key } }"
            class="d-block py-4 py-md-5 rounded-10 slide-item"
            :style="{
              overflow: 'hidden',
              backgroundColor: slide.color,
            }"
            @click.native="healthCheckClick(slide.text)"
          >
            <div class="flex-center flex-column">
              <div
                class="bg-img img-contain mb-3"
                :style="{
                  backgroundImage: `url(${require(`@/assets/img/${
                    $i18n.locale
                  }/ic-check-${idx + 1}.svg`)})`,
                  width: calcHeight('5rem'),
                  paddingBottom: calcHeight('5rem'),
                }"
              ></div>
              <div class="text-center fs-20 fw-7 gray-2">
                {{ slide.text }}
              </div>
            </div>
          </router-link>
        </sub-slide>
      </section>
      <!-- // 건강체크 -->

      <!--  건강정보 -->
      <section class="container">
        <sub-slide
          v-if="isArray($t('healthInfo.items')).length"
          :slides="isArray($t('healthInfo.items'))"
          :title="$t('healthInfo.title')"
          :subTitle="$t('healthInfo.subTitle')"
          :options="{
            slidesPerView: 4,
            spaceBetween: 40,
            breakpoints: {
              400: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              540: {
                slidesPerView: 3.1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            },
            navigation: {
              nextEl: '.sub-slide-2-next',
              prevEl: '.sub-slide-2-prev',
            },
          }"
          v-slot="{ slide, idx }"
        >
          <router-link
            :to="{
              name: slide.name,
              query: slide.query,
              params: { key: slide.key },
            }"
            class="position-relative flex-center-end rounded-10 slide-item"
            :style="{
              height: calcHeight('7rem'),
              background: `rgba(0,0,0, 0.5) url(${require(`@/assets/img/${
                $i18n.locale
              }/info-${idx + 1}.jpg`)})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundBlendMode: 'darken',
            }"
            @click.native="healthInfoClick(slide.text)"
          >
            <!-- 텍스트 -->
            <h6 class="fs-19 fw-7 white mb-3 fw-3">
              {{ slide.text }}
            </h6>
            <!-- // 텍스트 -->
          </router-link>
        </sub-slide>
      </section>
      <!-- // 건강정보 -->

      <!-- 라인 배경 -->
      <section>
        <div class="bg-linear main-liner"></div>
      </section>
      <!-- // 라인 배경 -->

      <!-- 게시판 -->
      <section v-if="type === 'kyobohealthcare' || type === 'newkyobohealthcare'">
        <home-board></home-board>
      </section>
      <!-- // 게시판 -->
    </div>
    <!-- 메인 페이지 -->

    <!-- 교보생명 부가 상품 서비스 소개 홈 -->
    <div v-else :style="{ marginBottom: calcHeight('5rem') }">
      <!-- 메인 비쥬얼 -->
      <section class="mb-5">
        <div
          class="bg-img position-relative main-slide d-none d-md-block"
          :style="{
            backgroundImage: `url(${require('@/assets/img/kyobohealthcare/visual-1.jpg')})`,
            paddingBottom: calcHeight('25rem', '3vh'),
            zIndex: '-2',
          }"
        >
          <b-container
            class="flex-center"
            :style="{
              height: calcHeight('25rem', '3vh'),
            }"
          >
            <p class="fs-28 text-center white">
              교보생명 상품부가서비스는 <br />교보생명의 보험상품에 가입하신
              분들에게 <br />
              제공해드리는 서비스 입니다.
            </p>
          </b-container>
          <div class="swiper-background"></div>
        </div>
        <div
          class="bg-img position-relative main-slide d-block d-md-none"
          :style="{
            backgroundImage: `url(${require('@/assets/img/kyobohealthcare/visual-1-mobile.jpg')})`,
            paddingBottom: calcHeight('12rem'),
            zIndex: '-2',
          }"
        >
          <b-container
            class="flex-center"
            :style="{
              height: calcHeight('12rem'),
            }"
          >
            <p class="fs-24 text-center white">
              교보생명 상품부가서비스는 <br />교보생명의 보험상품에 가입하신
              분들에게 <br />
              제공해드리는 서비스 입니다.
            </p>
          </b-container>
          <div class="swiper-background"></div>
        </div>
      </section>
      <!-- // 메인 비쥬얼 -->
      <!-- 우대 서비스 -->
      <section class="container">
        <sub-slide
          v-if="isArray($t('service')).length"
          :slides="isArray($t('service'))"
          :title="'상품부가서비스'"
          :options="{
            slidesPerView: 3,
            spaceBetween: 20,
            breakpoints: {
              360: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              400: {
                slidesPerView: 1.4,
                spaceBetween: 10,
              },
              460: {
                slidesPerView: 1.7,
                spaceBetween: 10,
              },
              574: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 18,
              },
              994: {
                slidesPerView: 2.5,
                spaceBetween: 18,
              },
            },
            navigation: {
              nextEl: '.service-silde-next',
              prevEl: '.service-silde-prev',
            },
          }"
          v-slot="{ slide }"
        >
          <b-btn
            variant="link bg-img"
            @click="handleClick(slide)"
            class="d-block gray-2 rounded-10 slide-item"
            :style="{
              backgroundColor: slide.bgColor,
            }"
          >
            <div class="p-4 flex-start-start flex-column">
              <span
                class="bg-img d-block mb-3"
                :style="{
                  backgroundImage: `url(${require(`@/assets/img/kyoboservices/ic-service-${slide.params.key}.svg`)})`,
                  backgroundSize: 'contain',
                  width: '20%',
                  paddingBottom: '20%',
                }"
              />
              <h4 class="fs-22 mb-2 text-left fw-7">
                {{ slide.title }}
              </h4>
              <p class="fs-18 text-left">{{ slide.text }}</p>
            </div>
          </b-btn>
        </sub-slide>
      </section>
      <!-- // 우대 서비스 -->
    </div>
    <modal-login-info v-if="loginState"></modal-login-info>
    <modal-end-alert v-if="loginEndState"></modal-end-alert>
    <!-- // 교보생명 부가 상품 서비스 홈 -->
  </div>
</template>

<script>
import SubSlide from "@/components/common/SubSlide";
import MainSlide from "@/components/common/MainSlide";
import HomeBoard from "@/components/kyobohealthcare/HomeBoard";
import ModalLoginInfo from "@/components/common/modal/ModalLoginInfo";
import ModalEndAlert from "@/components/common/modal/ModalEndAlert.vue";
import common from "@/mixins/common";
import { mapState } from "vuex";

export default {
  mixins: [common],
  components: {
    MainSlide,
    SubSlide,
    HomeBoard,
    ModalLoginInfo,
    ModalEndAlert,
  },
  
  data() {    
    return {
      autoplay: true
    };
  },
  computed: {
    ...mapState("auth", ["loggedIn"]),
    ...mapState("modal", ["loginEndState"]),
    ...mapState("modal", ["loginState"]),
  },
  mounted() {
    // console.log('Home mounted~~~~~~~~~~~~~')
    this.pageViewTrack("메인")
  },
  methods: {
    handleClick(object) {
      const { name, params, title } = object;
      this.clickTrack(title, "상품부가서비스");
      this.$router.push({ name, params: { key: params.key } });
    },
    healthCheckClick(title) {
      this.clickTrack(title, "건강체크");
    },
    healthInfoClick(title) {
      this.clickTrack(title, "건강정보");
    }
  },
};
</script>
