var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{ref:"provider",staticClass:"position-relative d-block",attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-input-group',{class:_vm.inputGroupClass},[_c('b-input',_vm._b({staticClass:"px-2 py-1 d-inline-block",attrs:{"name":_vm.name,"value":_vm.value},on:{"input":function($event){return _vm.inputListener($event)}}},'b-input',Object.assign({}, _vm.$props,
          {class: [
            _vm.inputClass,
            {
              'hide-icon': _vm.hideStateIcon,
            } ],
          style: Object.assign({}, _vm.styles)}),false)),_vm._l((_vm.$slots),function(_,slot){return [_vm._t(slot)]})],2),(_vm.isErrorMessage && _vm.rules && !_vm.getValidationState(validationContext))?_c('p',{class:[
        _vm.errPosLeft ? 'd-block d-md-none' : 'd-none',
        'red fs-20 fs-md-18 fw-3 w-100 text-nowrap mt-2' ]},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e(),(_vm.isErrorMessage && _vm.rules && !_vm.getValidationState(validationContext))?_c('p',{class:[
        _vm.errPosLeft ? 'd-none d-md-block' : 'mt-2',
        'red fs-20 fs-md-18 fw-3 position-absolute w-100 text-nowrap' ],style:(Object.assign({}, (_vm.errPosLeft && {
          top: '50%',
          left: '100%',
          transform: 'translateY(-50%)',
        }),
        (!_vm.errPosLeft && {
          top: '120%',
          left: '0',
          transform: 'translateY(-50%)',
        })))},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }