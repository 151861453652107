import { showToast } from "@/plugins/custom-toast";
import store from "@/store/index";
import { getCookie, setCookie } from "@/utils/cookies";
import instance from "@/utils/request";
import router from ".";

export default async (to, from, next, app) => {
  // type이 있을 때 type이 맞는지 확인
  const type = to.params.type;
  const isAuth = !!getCookie({ key: "token" });
  const code = getCookie({ key: "code" });
  const { memberdata = null } = to.query;
  const types = store.getters["types/getTypes"];

  // 알림톡 진입 체크에 필요
  const fullPath = to.fullPath;
  const query = to.query;

  console.log('to.name: ',to.name)

  if (to.name === "home") {
    const hideModalUntil = localStorage.getItem("hideModalUntil");
    const currentTime = new Date();

    if (hideModalUntil && new Date(hideModalUntil) > currentTime) {
      store.commit("modal/set", ["loginEndState", false]);// 오늘 자정 전까지 모달 숨기기
    } else {
      store.commit("modal/set", ["loginEndState", true]);; // 모달 표시
    }
  }

  // 알림톡 URL로 진입했을 때
  if (fullPath.includes("path=")) {
    console.log('알림톡으로 진입')
    const guid = query.guid;
    const comcode = query.comcode;
    const path = query.path;

    const data = {
      kyoboGuidData: guid,
      comCode: comcode
    }
    console.log('data: ', data)
    await instance.post("/auth/auto", data)
    .then((response) => {
      console.log("/auth/auto success", response);
      if (response.status.code === 200) {
        // 알림톡 URL 최초 진입 여부 체크하기 위해 설정
        setCookie({ key: "talk", value: true });
        // token, encryptionData, code cookie에 저장
        setCookie({ key: "token", value: response.data.token });
        setCookie({ key: "encryptionData", value: response.data.encryptionData });
        setCookie({ key: "code", value: response.data.code });

        // 중요!! 건강위험도체크/심리자가체크/힐링테라피(risk-check/self-check/therapy)일 경우 무조건 comcode: NK의 페이지를 띄운다. (컨텐츠 보여주기용이기 때문에 무관)
        if (path === 'risk-check' || path === 'self-check' || path === 'therapy') {
          setCookie({ key: "code", value: "NK" });
          return next({ name: "check", params: { type: types['NK'], key: path }, query: null });
        } else {
          if (comcode === "KR") { // 교보주니어헬스케어
            console.log('type: ', types[response.data.code], 'key: ', path);
            return next({ name: "check-survey", params: { type: types[response.data.code], key: path.split('/')[0], id: path.split('/')[1] }, query: null });
          } else { // 교보new헬스케어
            // path: 건강위험도체크/심리자가체크/힐링테라피(risk-check/self-check/therapy)
            return next({ name: "check", params: { type: types[response.data.code], key: path }, query: null });
          }
        }
      }
    })
    .catch((error) => {
      console.log("/auth/auto error", error)
    })
  } else {
    // memebr data 있을 때
    if (memberdata) {
      try {
        if (memberdata === "9999") {
          await store.dispatch("auth/logout");
          return next({ name: "home", params: { type: "kyoboservices" } });
        }
        // memberdata 있으면 로그인
        store.commit("auth/set", ["loading", true]);
        const newCode = await store.dispatch("auth/login", memberdata);

        showToast({ title: "로그인 되었습니다." });
        return next({ name: "home", params: { type: types[newCode] } });
      } catch (err) {
        console.log(err);
        showToast({
          title: "관리자에게 문의해주세요.",
          variant: "danger",
        });
        return next({ name: "home", params: { type: "kyoboservices" } });
      } finally {
        store.commit("auth/set", ["loading", false]);
      }
    }

    // 토큰 있을 때
    if (isAuth) {
      store.commit("auth/set", ["loggedIn", true]);
      return type === types[code] ? next() : next({ name: "home", params: { type: types[code] }, query: null });
    }   

    // 토근없고 교보상품부가서비스가 아닐때
    if (!isAuth && !["kyoboservices"].includes(type)) {
      // showToast({
      //   title: "로그인이 필요합니다.",
      //   variant: "info",
      // });
      store.commit("modal/set", ["loginState", true]);
      return next({ name: "home", params: { type: "kyoboservices" } });
    }
    next();
  }
  next();
};
