var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-center py-4"},[_c('b-pagination-nav',{staticClass:"mt-4",attrs:{"number-of-pages":_vm.pageNum,"last-number":"","first-number":"","link-gen":_vm.linkGen},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('span',{staticClass:"bg-img",style:({
          transform: 'rotate(180deg)',
          backgroundImage: ("url(" + (require('@/assets/img/common/ic-pagi-arrow.svg')) + ")"),
        })})]},proxy:true},{key:"next-text",fn:function(){return [_c('span',{staticClass:"bg-img",style:({
          backgroundImage: ("url(" + (require('@/assets/img/common/ic-pagi-arrow.svg')) + ")"),
        })})]},proxy:true},{key:"page",fn:function(ref){
        var page = ref.page;
        var active = ref.active;
return [(active)?_c('span',{staticClass:"fs-15 fw-7"},[_vm._v(_vm._s(page))]):_c('span',{staticClass:"fs-15 fw-7",style:({ color: '#767676' })},[_vm._v(_vm._s(page))])]}}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }