<template>
  <b-modal id="modal-end-alert"
    size="login"
    v-model="modalShow"
    :hide-footer="true"
    body-class="px-3 px-md-4 py-0 pb-3"
    header-class="px-3 px-md-4 border-bottom-0 d-block"
    scrollable
    centered
    >
    <template #modal-header="{ close }">
      <b-row align-v="center">
        <b-col>
          <h3 class="flex-center fs-24 fs-md-20 fw-7 mt-1" :class="serviceType()">{{ title }} 이용 공지</h3>
        </b-col>
      </b-row>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="modal-body fs-18">
      <div class="info">
        <p>안녕하세요.</p>
        <p>{{ title }}를 이용해주시는 고객님께 항상 감사의<br/>말씀을 전합니다.</p>
          <br />
          <p>2025년부터 서비스 운영사가 아래와 같이 변경됩니다.</p>
          <br />
          <p><span class="fw-7">◼ 기존 운영사</span> : GC케어, 에임메드</p>
          <p><span class="fw-7">◼ 변경 운영사</span> : 교보다솜케어</p>
          <p><span class="fw-7">◼ 변경일</span> : 2025년 1월 2일</p>
          <br />
          <p class="fw-7 custom-font" :class="serviceType()">[서비스 변경사항]</p>
          <br />
          <p class="fw-7">▣ {{ title }} 콜센터 번호 변경:</p>
          <p>서비스 종류와 상관없이 {{ title }} 콜센터 번호가 <span class="fw-7">1588-7524</span>로 통합 운영됩니다.</p>
          <br />
          <p class="fw-7">▣ 홈페이지 개편:</p>
          <p>{{ title }} 홈페이지는 <span class="fw-7">2025년 4월 이후</span> 개편하여 오픈될 예정입니다.</p>
          <br />
          <p class="fw-7">{{ title }} 콜센터 상담은 변경 없이 계속해서 제공됩니다.</p>
          <p>더 나은 서비스를 제공하기 위해 최선을 다하겠습니다.</p>
          <br />
          <p>감사합니다.</p>
      </div>
      <div class="py-4">
        <w-checkbox
          :label="'오늘 하루 그만보기'"
          :name="'오늘 하루 그만보기'"
          v-model="privacy"
          class="py-1 mr-2"
          @change="checkModalToday"
        >
          <a class="fs-20 fs-md-18 fs-md-18">오늘 하루 그만보기</a>
        </w-checkbox>        
      </div>
    </div>
    <button class="modal-close mt-2" :class="serviceType()" @click="closeModal()">닫기</button>
  </b-modal>
</template>

<script>
import store from "@/store/index";
export default {
  components: {
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    title() {
      return this.GetTitle();
    }
  },
  data() {
    return {
      modalShow: true,
      privacy: false,      
      resizeW: window.innerWidth
    };
  },
  methods: {
    serviceType () {
      if (this.type === 'kyobowomancare') {
        return 'woman'
      } else if (this.type === 'kyobokidscare') {
        return 'kids'
      } else if (this.type === 'kyobojuniorcare') {
        return 'junior'
      } else if (this.type === 'kyoboseniorcare') {
        return 'senior'
      }
    },
    GetTitle(){
      if (this.type === 'kyobowomancare') {
        return '우먼헬스케어서비스'
      } else if (this.type === 'kyobokidscare') {
        return '어린이헬스케어서비스'
      } else if (this.type === 'kyobojuniorcare') {
        return '주니어헬스케어서비스'
      } else if (this.type === 'kyoboseniorcare') {
        return '치매케어서비스'
      } else if (this.type === 'kyobohealthcare') {
        return '헬스케어서비스'
      } else if (this.type === 'newkyobohealthcare') {
        return 'new헬스케어'
      } else {
        return '교보상품서비스'
      }
    },
    checkModalToday(){
      if (this.privacy) {
        const now = new Date();
        const expireDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59); // 오늘 자정
        localStorage.setItem("hideModalUntil", expireDate.toISOString());
      } else {
        localStorage.removeItem("hideModalUntil");
      }
    },
    closeModal () {
      this.$bvModal.hide('modal-end-alert')
      store.commit("modal/set", ["loginEndState", false]);
    },
    handleResize(event) {
      this.resizeW = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
};
</script>
<style scoped lang="scss">
.modal .modal-login {
  width: 600px !important;
  max-width: 600px !important;
  h3 {
    border-bottom: 3px solid #4ca223;
    text-align: left; 
    padding: 1em 0 1.3em 0;
    &.woman {
      border-bottom: 3px solid #ffafd3 !important;
    }
    &.kids {
      border-bottom: 3px solid #5d5d5d !important;
    }
    &.junior {
      border-bottom: 3px solid #b0dfff !important;
    }
    &.senior {
      border-bottom: 3px solid #c2c4f5 !important;
    }
  }
  .point {
    text-align: center;
    color: #4ca223;
    font-weight: bold;
    font-size: 0.85em;
    &.woman {
      color: #ffafd3 !important;
      .triangle {
        border-top: 10px solid #ffafd3 !important;
      }
      .point-img {
        border: 6px solid #ffafd3 !important;
      }
    }
    &.kids {
      color: #5d5d5d !important;
      .triangle {
        border-top: 10px solid #5d5d5d !important;
      }
      .point-img {
        border: 6px solid #5d5d5d !important;
      }
    }
    &.junior {
      color: #b0dfff !important;
      .triangle {
        border-top: 10px solid #b0dfff !important;
      }
      .point-img {
        border: 6px solid #b0dfff !important;
      }
    }
    &.senior {
      color: #c2c4f5 !important;
      .triangle {
        border-top: 10px solid #c2c4f5 !important;
      }
      .point-img {
        border: 6px solid #c2c4f5 !important;
      }
    }
    .triangle {
      width: 0;
      height: 0;
      border-top: 10px solid #4ca223;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      display: block;
      margin: 0 auto;
    }
    .point-img {
      border: 6px solid #4ca223;
      border-radius: 2em;
      width: 120px;
      margin: 4px auto 1.5em auto;
    }
  }
  .info {
    padding: 2.3em 2em;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    letter-spacing: -0.75px;
    text-align: left;
  }
}
.modal-close {
  display: flex;
  width: 150px;
  height: 50px;
  margin: 0 auto 2em auto;
  border-radius: 8px;
  background: #4ca223;
  color: #fff;
  align-items: center;
  justify-content: center;
  &.woman {
    background: #ffafd3 !important;
  }  
  &.kids {
    background: #5d5d5d !important;
  }
  &.junior {
    background: #b0dfff !important;
  }
  &.senior {
    background: #c2c4f5 !important;
  }
}
.custom-font {
  color: #4ca223;
  &.woman {
    color: #ffafd3 !important;
  }  
  &.kids {
    color: #5d5d5d !important;
  }
  &.junior {
    color: #b0dfff !important;
  }
  &.senior {
    color: #c2c4f5 !important;
  }
}
</style>
