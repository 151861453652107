var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!['kyoboservices'].includes(_vm.type))?_c('div',{staticClass:"home"},[_c('section',{staticClass:"main-slide-section"},[(_vm.isArray(_vm.$t('visual')).length)?_c('main-slide',{attrs:{"slide":_vm.isArray(_vm.$t('visual')),"card":['kyobohealthcare', 'kyobowomancare', 'kyobojuniorcare'].includes(
            _vm.type
          )}}):_vm._e()],1),(typeof _vm.$t('healthCheck') === 'object')?_c('section',{staticClass:"container mb-5"},[(_vm.isArray(_vm.$t('healthCheck.items')).length)?_c('sub-slide',{attrs:{"slides":_vm.isArray(_vm.$t('healthCheck.items')),"title":_vm.$t('healthCheck.title'),"subTitle":_vm.$t('healthCheck.subTitle'),"options":{
          slidesPerView: 3,
          spaceBetween: 18,
          breakpoints: {
            400: {
              slidesPerView: 1.8,
              spaceBetween: 10,
            },
            574: {
              slidesPerView: 2.5,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.7,
              spaceBetween: 18,
            },
            994: {
              slidesPerView: 3,
              spaceBetween: 18,
            },
          },
          navigation: {
            nextEl: '.sub-slide-next',
            prevEl: '.sub-slide-prev',
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var slide = ref.slide;
        var idx = ref.idx;
return [_c('router-link',{staticClass:"d-block py-4 py-md-5 rounded-10 slide-item",style:({
            overflow: 'hidden',
            backgroundColor: slide.color,
          }),attrs:{"to":{ name: slide.name, params: { key: slide.key } }},nativeOn:{"click":function($event){return _vm.healthCheckClick(slide.text)}}},[_c('div',{staticClass:"flex-center flex-column"},[_c('div',{staticClass:"bg-img img-contain mb-3",style:({
                backgroundImage: ("url(" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/ic-check-" + (idx + 1) + ".svg"))) + ")"),
                width: _vm.calcHeight('5rem'),
                paddingBottom: _vm.calcHeight('5rem'),
              })}),_c('div',{staticClass:"text-center fs-20 fw-7 gray-2"},[_vm._v(" "+_vm._s(slide.text)+" ")])])])]}}],null,false,3597280294)}):_vm._e()],1):_vm._e(),_c('section',{staticClass:"container"},[(_vm.isArray(_vm.$t('healthInfo.items')).length)?_c('sub-slide',{attrs:{"slides":_vm.isArray(_vm.$t('healthInfo.items')),"title":_vm.$t('healthInfo.title'),"subTitle":_vm.$t('healthInfo.subTitle'),"options":{
          slidesPerView: 4,
          spaceBetween: 40,
          breakpoints: {
            400: {
              slidesPerView: 2.2,
              spaceBetween: 20,
            },
            540: {
              slidesPerView: 3.1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3.5,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          },
          navigation: {
            nextEl: '.sub-slide-2-next',
            prevEl: '.sub-slide-2-prev',
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var slide = ref.slide;
        var idx = ref.idx;
return [_c('router-link',{staticClass:"position-relative flex-center-end rounded-10 slide-item",style:({
            height: _vm.calcHeight('7rem'),
            background: ("rgba(0,0,0, 0.5) url(" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/info-" + (idx + 1) + ".jpg"))) + ")"),
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'darken',
          }),attrs:{"to":{
            name: slide.name,
            query: slide.query,
            params: { key: slide.key },
          }},nativeOn:{"click":function($event){return _vm.healthInfoClick(slide.text)}}},[_c('h6',{staticClass:"fs-19 fw-7 white mb-3 fw-3"},[_vm._v(" "+_vm._s(slide.text)+" ")])])]}}],null,false,1158022567)}):_vm._e()],1),_vm._m(0),(_vm.type === 'kyobohealthcare' || _vm.type === 'newkyobohealthcare')?_c('section',[_c('home-board')],1):_vm._e()]):_c('div',{style:({ marginBottom: _vm.calcHeight('5rem') })},[_c('section',{staticClass:"mb-5"},[_c('div',{staticClass:"bg-img position-relative main-slide d-none d-md-block",style:({
          backgroundImage: ("url(" + (require('@/assets/img/kyobohealthcare/visual-1.jpg')) + ")"),
          paddingBottom: _vm.calcHeight('25rem', '3vh'),
          zIndex: '-2',
        })},[_c('b-container',{staticClass:"flex-center",style:({
            height: _vm.calcHeight('25rem', '3vh'),
          })},[_c('p',{staticClass:"fs-28 text-center white"},[_vm._v(" 교보생명 상품부가서비스는 "),_c('br'),_vm._v("교보생명의 보험상품에 가입하신 분들에게 "),_c('br'),_vm._v(" 제공해드리는 서비스 입니다. ")])]),_c('div',{staticClass:"swiper-background"})],1),_c('div',{staticClass:"bg-img position-relative main-slide d-block d-md-none",style:({
          backgroundImage: ("url(" + (require('@/assets/img/kyobohealthcare/visual-1-mobile.jpg')) + ")"),
          paddingBottom: _vm.calcHeight('12rem'),
          zIndex: '-2',
        })},[_c('b-container',{staticClass:"flex-center",style:({
            height: _vm.calcHeight('12rem'),
          })},[_c('p',{staticClass:"fs-24 text-center white"},[_vm._v(" 교보생명 상품부가서비스는 "),_c('br'),_vm._v("교보생명의 보험상품에 가입하신 분들에게 "),_c('br'),_vm._v(" 제공해드리는 서비스 입니다. ")])]),_c('div',{staticClass:"swiper-background"})],1)]),_c('section',{staticClass:"container"},[(_vm.isArray(_vm.$t('service')).length)?_c('sub-slide',{attrs:{"slides":_vm.isArray(_vm.$t('service')),"title":'상품부가서비스',"options":{
          slidesPerView: 3,
          spaceBetween: 20,
          breakpoints: {
            360: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 1.4,
              spaceBetween: 10,
            },
            460: {
              slidesPerView: 1.7,
              spaceBetween: 10,
            },
            574: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 18,
            },
            994: {
              slidesPerView: 2.5,
              spaceBetween: 18,
            },
          },
          navigation: {
            nextEl: '.service-silde-next',
            prevEl: '.service-silde-prev',
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var slide = ref.slide;
return [_c('b-btn',{staticClass:"d-block gray-2 rounded-10 slide-item",style:({
            backgroundColor: slide.bgColor,
          }),attrs:{"variant":"link bg-img"},on:{"click":function($event){return _vm.handleClick(slide)}}},[_c('div',{staticClass:"p-4 flex-start-start flex-column"},[_c('span',{staticClass:"bg-img d-block mb-3",style:({
                backgroundImage: ("url(" + (require(("@/assets/img/kyoboservices/ic-service-" + (slide.params.key) + ".svg"))) + ")"),
                backgroundSize: 'contain',
                width: '20%',
                paddingBottom: '20%',
              })}),_c('h4',{staticClass:"fs-22 mb-2 text-left fw-7"},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('p',{staticClass:"fs-18 text-left"},[_vm._v(_vm._s(slide.text))])])])]}}],null,false,1038538588)}):_vm._e()],1)]),(_vm.loginState)?_c('modal-login-info'):_vm._e(),(_vm.loginEndState)?_c('modal-end-alert'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"bg-linear main-liner"})])}]

export { render, staticRenderFns }