var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-slide position-relative mb-5 mb-md-0"},[_c('swiper',{ref:"swiper",staticClass:"white position-relative",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.slide),function(s,idx){return _c('swiper-slide',{key:idx},[_c('div',{staticClass:"d-flex w-100 h-100"},[_c('b-container',{staticClass:"flex-center position-relative px-0"},[_c('b-row',{staticClass:"text-center"},[_c('b-col',{staticClass:"white fw-7 fs-30 fs-md-38 mb-2",attrs:{"cols":"12","data-swiper-parallax":"-1000"}},[_vm._v(" "+_vm._s(s.title)+" ")]),_vm._l((s.subTitle),function(t){return _c('b-col',{key:t,staticClass:"white fs-22 fs-md-19 fw-md-3",attrs:{"cols":"12","data-swiper-parallax":"-800"},domProps:{"innerHTML":_vm._s(t)}})}),_c('b-col',{staticClass:"mt-3 mt-md-4",attrs:{"data-swiper-parallax":"-600"}},[_c('router-link',{attrs:{"to":{ name: s.to, hash: s.hash, params: { key: s.key } }},nativeOn:{"click":function($event){return _vm.detailClick(s.title)}}},[_c('img',{staticClass:"main-slide-btn",style:({
                    width: _vm.calcHeight('10rem'),
                  }),attrs:{"src":("" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/ic-main-slide-btn.svg")))),"alt":"자세히 보기"}})])],1)],2)],1)],1),_c('div',{staticClass:"position-absolute w-100 h-100 d-none d-md-block",style:({
          backgroundImage: ("url(" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/visual-" + (idx + 1) + ".jpg"))) + ")"),
          zIndex: '-2',
          top: 0,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        })}),_c('div',{staticClass:"position-absolute w-100 h-100 d-block d-md-none",style:({
          backgroundImage: ("url(" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/visual-" + (idx + 1) + "-mobile.jpg"))) + ")"),
          zIndex: '-2',
          top: 0,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        })}),_c('div',{staticClass:"swiper-background"})])}),1),_c('b-container',{staticClass:"position-relative"},[(_vm.card)?_c('b-btn',{style:({
        right: 0,
        top: '-3.5rem',
        zIndex: 10,
      }),attrs:{"variant":"link position-absolute member-ship-btn","data-swiper-parallax":"-1000"},on:{"click":function($event){$event.preventDefault();return _vm.showMemberShip(_vm.$i18n.locale)}}},[_c('img',{style:({ width: _vm.calcHeight('12rem', '4vh') }),attrs:{"src":("" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/ic-member-ship-btn.svg")))),"alt":"멤버쉽 카드 보기"}})]):_vm._e()],1),_c('div',{staticClass:"swiper-slide-wrap flex-center py-4"},[_c('div',{staticClass:"main-swiper-pagination flex-center",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"control-wrap ml-2 d-flex"},[_c('b-btn',{staticClass:"mr-2",attrs:{"variant":"link"},on:{"click":_vm.handleAuto}},[_c('span',{staticClass:"bg-img d-none d-md-block",style:(Object.assign({}, {width: '3rem',
            paddingBottom: '3rem'},
            (!_vm.autoplay && {
              backgroundImage: ("url(" + (require('@/assets/img/common/ic-play.svg')) + ")"),
            }),
            (_vm.autoplay && {
              backgroundImage: ("url(" + (require('@/assets/img/common/ic-stop.svg')) + ")"),
            })))})])],1)]),(_vm.card)?[(['kyobojuniorcare'].includes(_vm.$i18n.locale))?_c('modal-pet-care'):_vm._e(),(['kyobowomancare'].includes(_vm.$i18n.locale))?_c('modal-woman-member-ship'):_c('modal-member-ship')]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }