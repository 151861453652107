var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(['checkbox'].includes(_vm.answersType))?_c('validation-provider',{ref:"provider",attrs:{"name":_vm.name,"rules":"required"}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(0),expression:"0"}],attrs:{"type":"text"},domProps:{"value":_vm.value}}),_c('b-row',{staticClass:"mx-n2 mx-md-n3",attrs:{"align-v":"stretch"}},_vm._l((_vm.answers),function(item,i){return _c('b-col',{key:i,staticClass:"px-2 px-md-3 py-2",attrs:{"cols":"12","sm":"6"}},[_c('b-btn',{staticClass:"border rounded-10 p-3 flex-between w-100 h-100",class:[
            _vm.value[i]
              ? 'border-theme-color-1 theme-color-1'
              : 'border-black black',
            _vm.type === 'newkyobohealthcare' ? 'border-gray-3 gray-3' : ''
          ],attrs:{"variant":"link"},on:{"click":function($event){return _vm.handleCheckBoxClick(item.value, i)}}},[_c('span',{staticClass:"text-left fs-20"},[_vm._v(_vm._s(item.answertitle))]),_c('i',{staticClass:"icon-ok fs-20"})])],1)}),1)],1):(['radio'].includes(_vm.answersType))?_c('validation-provider',{ref:"provider",attrs:{"name":_vm.name,"rules":"required"}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(0),expression:"0"}],attrs:{"type":"text"},domProps:{"value":_vm.value}}),_c('b-row',{staticClass:"mx-n2 mx-md-n3"},_vm._l((_vm.answers),function(item,i){return _c('b-col',{key:i,staticClass:"px-2 px-md-3 py-2",attrs:{"cols":"12","sm":"6"}},[_c('b-btn',{staticClass:"border rounded-10 p-3 flex-between w-100",class:[
            _vm.value[i] === item.value
              ? 'border-theme-color-1 theme-color-1'
              : 'border-black black',
            _vm.type === 'newkyobohealthcare' ? 'border-gray-3 gray-3' : ''
          ],attrs:{"variant":"link"},on:{"click":function($event){return _vm.handleRadioClick(item.value, i)}}},[_c('span',{staticClass:"text-left fs-20"},[_vm._v(_vm._s(item.answertitle || ((i + 1) + " 번")))]),_c('i',{staticClass:"icon-ok fs-20"})])],1)}),1)],1):(_vm.answersType === 'selectbox')?_c('div',[_c('hc-select-default',{staticClass:"w-100",attrs:{"items":_vm.selectBoxAnwsers,"value":_vm.value && _vm.value[0],"styles":{ minWidth: '8rem' },"rules":"required","dropdownClass":_vm.value && _vm.value[0]
          ? 'border border-theme-color-1  rounded-10 p-3 w-100'
          : 'border border-black rounded-10 p-3 w-100'},on:{"change":function($event){_vm.handleChange($event);
        _vm.$emit('changeSlide');}}})],1):(_vm.answersType === 'input')?[_c('b-row',{staticClass:"mx-n2 mx-md-n3"},_vm._l((_vm.answers),function(answer,i){return _c('b-col',{key:("answer-input-" + i),staticClass:"px-2 px-md-3  py-2",attrs:{"cols":"6","sm":"4"}},[_c('h5',{staticClass:"gray fs-20 fw-7 mb-3"},[_vm._v(_vm._s(answer.answertitle))]),(answer.inputType === 'number')?_c('div',{staticClass:"flex-start"},[_c('hc-input-default',{attrs:{"type":answer.inputType,"rules":"max:3|required","name":answer.name,"styles":{
              minWidth: _vm.calcHeight('8rem'),
              height: _vm.calcHeight('3.5rem'),
            },"inputClass":_vm.value[i]
                ? 'border-theme-color-1  rounded-10 p-3 fs-20'
                : 'border-black rounded-10 p-3 fs-20',"value":_vm.value[i]},on:{"change":function($event){return _vm.handleInputChange($event, i)}}}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(answer.text))])],1):(answer.inputType === 'date')?_c('div',[_c('b-btn',{attrs:{"variant":"link border-0"},on:{"click":function($event){return _vm.$bvModal.show('modal-date-input')}}},[_c('hc-input-default',{staticClass:"border rounded-10 p-2 black",class:_vm.value[i] ? 'border-theme-color-1 ' : 'border-black',style:({
                minWidth: '10rem',
              }),attrs:{"inputClass":"bg-white border-0 fs-20","rules":"required","isErrorMessage":false,"name":answer.name,"value":_vm.value[i],"readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"flex-center"},[_c('i',{staticClass:"icon-calendar"})])]},proxy:true}],null,true)})],1),_c('modal-date-picker',{attrs:{"id":'modal-date-input'},on:{"change":function($event){return _vm.handleInputChange($event, i)}}})],1):_vm._e()])}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }