var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-pet-care","size":"md","hide-footer":true,"body-class":"p-0","header-class":"p-0 d-block border-bottom-0","modal-class":"modal-pet-care","centered":"","scrollable":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"px-4 px-md-5 py-4 flex-between"},[_c('h3',{staticClass:"fs-24 fw-7 mt-1"},[_vm._v(" 펫케어제휴서비스 ")]),_c('b-btn',{style:({ marginRight: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: ("url(" + (require('@/assets/img/common/ic-close.svg')) + ")"),
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[_c('div',{staticClass:"px-4 px-md-5 pb-5"},[_c('p',{staticClass:"p-3 mb-4 rounded-10 border fs-18 text-center",style:({
        color: '#323232',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      })},[_vm._v(" 우리의 소중한 가족 반려동물을 위한 모든 혜택을 펫케어카드를 통해 받아보세요!! ")]),_c('b-row',{staticClass:"mx-n2"},[_c('b-col',{staticClass:"text-center fs-20 py-1 px-2",attrs:{"cols":"6"}},[_c('span',[_vm._v("펫케어 제휴번호")])])],1),_c('b-row',{staticClass:"mx-n2 mb-4"},[_c('b-col',{staticClass:"px-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coupon),expression:"coupon"}],ref:"coupon",staticClass:"d-none",attrs:{"readonly":"","type":"text"},domProps:{"value":(_vm.coupon)},on:{"input":function($event){if($event.target.composing){ return; }_vm.coupon=$event.target.value}}}),_c('p',{staticClass:"theme-color-1 fs-24 rounded-10 border fw-7 text-center py-3"},[_vm._v(" "+_vm._s(_vm.coupon)+" ")])]),_c('b-col',{staticClass:"px-2"},[_c('b-btn',{staticClass:"d-block rounded-10 bg-theme-color-1 white fs-20 w-100 h-100 py-3",on:{"click":_vm.copyAddr}},[_vm._v("제휴번호 복사하기")])],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"fs-20"},[_vm._v("사용방법")])]),_c('b-col',{staticClass:"fs-20 fw-3 gray px-0"},[_c('hash-title',{attrs:{"isHash":false,"title":"<a href='https://www.petcarecard.co.kr/' class='fs-20 fw-3 gray' target='_blank' >https://www.petcarecard.co.kr/</a>"}}),_c('span',[_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("제휴사할인 "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("멤버쉽 회원 가입 "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("마이페이지 "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("반려동물 정보 입력 "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("쿠폰 사용 "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("할인 정보 확인 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }