var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.meta.main)?_c('b-container',[_c('div',{staticClass:"row d-none d-md-flex py-3"},_vm._l((_vm.items),function(info,idx){return _c('div',{key:idx,staticClass:"col-4 py-5"},[_c('h3',{staticClass:"fs-18 fw-7 mb-3"},[_c('router-link',{staticClass:"flex-start",attrs:{"to":{ name: info.name, params: info.params }},nativeOn:{"click":function($event){return _vm.listClick(info)}}},[(idx === _vm.items.length - 1)?_c('div',{staticClass:"bg-img mr-2",style:({
                width: '1rem',
                paddingBottom: '1rem',
                backgroundSize: '200%',
                backgroundImage: ("url(" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/ic-plus.svg"))) + ")"),
              })}):_vm._e(),_vm._v(" "+_vm._s(info.title))])],1),(!_vm.loading)?_c('ul',_vm._l((info.lists),function(row,i){return _c('li',{key:i,staticClass:"list col-8 fw-3 text-truncate px-0 mb-2 gray-2"},[_c('router-link',{attrs:{"to":{
                name: 'consult-detail',
                params: info.params,
                query: _vm.getQuery(row, info.title),
              }},nativeOn:{"click":function($event){return _vm.listClick(info)}}},[_vm._v(" - "+_vm._s(row.title)+" ")])],1)}),0):(idx !== _vm.items.length - 1)?_c('b-row',_vm._l((3),function(i){return _c('b-col',{key:i,attrs:{"cols":"12"}},[_c('b-skeleton',{staticClass:"mb-3",attrs:{"animation":null,"width":"80%","height":"1.2rem"}})],1)}),1):_vm._e()],1)}),0)]):_vm._e(),(_vm.meta.main)?_c('b-container',{staticClass:"bg-white d-md-none px-0",style:({ borderBottom: '2px solid #b2b2b2' })},[_c('b-row',{staticClass:"d-flex d-md-none text-center mx-0 "},[_c('b-col',{staticClass:"col"},[_c('router-link',{staticClass:"btn btn-link fs-20 fw-7 black py-4 py-sm-5",attrs:{"to":{ name: 'consult', params: { key: 'online' } }}},[_vm._v("전문가 상담실")])],1),_c('b-col',{staticClass:"col border-left border-gray-3"},[_c('router-link',{staticClass:"btn btn-link fs-20 fw-7 black py-4 py-sm-5",attrs:{"to":{ name: 'ask', params: { key: 'ask' } }}},[_vm._v("자주 찾는 질문")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }