var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-member-ship","size":"md","hide-footer":true,"body-class":"p-0","header-class":"p-0 d-block border-bottom-0","modal-class":"modal-member-ship","centered":"","scrollable":""},on:{"show":_vm.showCb},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"px-5 py-4 flex-between"},[_c('h3',{staticClass:"fs-24 fw-7 mt-1"},[_vm._v(" 멤버쉽 카드 ")]),_c('b-btn',{style:({ marginRight: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: ("url(" + (require('@/assets/img/common/ic-close.svg')) + ")"),
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[_c('div',{staticClass:"px-5 pb-5"},[_c('div',{staticClass:"position-relative"},[(_vm.memberGradeStatus)?_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":("" + (require(("@/assets/img/" + (_vm.$i18n.locale) + "/" + _vm.cardImg)))),"alt":"member ship card"}}),_c('p',{staticClass:"text-right white",style:({
            position: 'absolute',
            bottom: '1.2rem',
            right: '1rem',
          })},[_c('span',[_vm._v(_vm._s(_vm.name))])])]):_c('b-skeleton-img',{staticClass:"rounded-10",attrs:{"no-aspect":"","height":"16.5rem"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }