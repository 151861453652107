var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 35,
  })},[_c('section',{staticClass:"bg-white vw-99 position-relative",class:_vm.isTalk ? 'display-none' : '',style:({
      zIndex: 35,
    }),attrs:{"id":"header"}},[_c('b-container',{style:({
        transition: '0.8s',
      })},[_c('div',{staticClass:"py-3 pt-md-4 pb-md-3"},[_c('div',{staticClass:"d-none d-md-flex flex-between align-items-end "},[_c('div',{staticClass:"dflex flex-start"},[_c('h1',[_c('router-link',{staticClass:"fs-24 fw-7 btn btn-link d-flex align-items-end theme-color-1",attrs:{"to":{ name: 'home', params: { type: _vm.type } }}},[_c('span',{staticClass:"bg-img img-contain",style:({
                    backgroundImage: ("url(" + (require('@/assets/img/common/logo.svg'))),
                    width: _vm.calcHeight('6.25rem'),
                    paddingBottom: _vm.calcHeight('3.125rem'),
                    marginBottom: '0.375rem',
                  })}),_c('span',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(_vm.$t("logo")))])])],1)]),_c('div',{staticClass:"flex-end align-items-end",style:({ marginBottom: '2px' })},[(!_vm.loggedIn)?[_c('b-btn',{staticClass:"mr-4 flex-center blue fw-7 fs-15",attrs:{"variant":"link"},on:{"click":_vm.login}},[_c('div',{staticClass:"bg-img mr-2",style:({
                    backgroundImage: ("url(" + (require('@/assets/img/common/ic-user.svg')) + ")"),
                    width: _vm.calcHeight('1.875rem'),
                    paddingBottom: _vm.calcHeight('1.875rem'),
                  })}),_c('span',{staticClass:"d-block"},[_vm._v("로그인")])])]:[_c('b-btn',{staticClass:"mr-4 flex-center blue fw-7 fs-15",attrs:{"variant":"link"},on:{"click":_vm.logout}},[_c('div',{staticClass:"bg-img mr-2",style:({
                    backgroundImage: ("url(" + (require('@/assets/img/common/ic-logout.svg')) + ")"),
                    width: _vm.calcHeight('1.875rem'),
                    paddingBottom: _vm.calcHeight('1.875rem'),
                  })}),_c('span',{staticClass:"d-block"},[_vm._v("로그아웃")])])],(typeof _vm.$t('myServices') === 'object' && _vm.loggedIn)?_c('b-dropdown',{attrs:{"no-caret":"","variant":"link","toggle-class":"mr-4 flex-center d-flex blue fw-7 reset-btn","menu-class":"fs-16"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"bg-img mr-2",style:({
                    backgroundImage: ("url(" + (require('@/assets/img/common/ic-book-open.svg')) + ")"),
                    width: _vm.calcHeight('1.875rem'),
                    paddingBottom: _vm.calcHeight('1.875rem'),
                  })}),_c('span',{staticClass:"fs-15"},[_vm._v("내 서비스 내역")])]},proxy:true}],null,false,207767083)},_vm._l((_vm.$t('myServices')),function(row){return _c('b-dropdown-item',{key:row.title,attrs:{"to":{ name: row.name, params: row.params, query: row.query }}},[_vm._v(_vm._s(row.title))])}),1):_vm._e(),_c('b-btn',{directives:[{name:"b-toggle",rawName:"v-b-toggle.side-bar-menu",modifiers:{"side-bar-menu":true}}],staticClass:"flex-center blue fw-7 fs-15",attrs:{"variant":"link"}},[_c('div',{staticClass:"bg-img mr-2",style:({
                  backgroundImage: ("url(" + (require('@/assets/img/common/ic-menu.svg')) + ")"),
                  width: _vm.calcHeight('1.875rem'),
                  paddingBottom: _vm.calcHeight('1.875rem'),
                })}),_c('span',{staticClass:"d-block"},[_vm._v("메뉴")])])],2)]),_c('div',{staticClass:"d-flex d-md-none flex-between"},[_c('b-btn',{staticClass:"flex-center blue fw-7 fs-15",attrs:{"variant":"link"},on:{"click":function($event){!_vm.loggedIn ? _vm.login() : _vm.logout()}}},[_c('div',{staticClass:"bg-img",style:(Object.assign({}, (!_vm.loggedIn && {
                  backgroundImage: ("url(" + (require('@/assets/img/common/ic-user.svg')) + ")"),
                }),
                (_vm.loggedIn && {
                  backgroundImage: ("url(" + (require('@/assets/img/common/ic-logout.svg')) + ")"),
                }),

                {width: '1.875rem',
                paddingBottom: '1.875rem'}))})]),_c('div',{staticClass:"dflex flex-start"},[_c('h1',[_c('router-link',{staticClass:"fs-24 fw-7 btn btn-link d-flex align-items-end theme-color-1",attrs:{"to":{ name: 'home', params: { type: _vm.type } }}},[_c('span',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(_vm.$t("logo")))])])],1)]),_c('b-btn',{directives:[{name:"b-toggle",rawName:"v-b-toggle.side-bar-menu",modifiers:{"side-bar-menu":true}}],staticClass:"flex-center blue fw-7 fs-15",attrs:{"variant":"link"}},[_c('div',{staticClass:"bg-img",style:({
                backgroundImage: ("url(" + (require('@/assets/img/common/ic-menu.svg')) + ")"),
                width: _vm.calcHeight('1.875rem'),
                paddingBottom: _vm.calcHeight('1.875rem'),
              })})])],1)])]),_c('div',{staticClass:"position-relative border-bottom-linear"})],1),_c('section',{staticClass:"w-100 position-absolute",style:(Object.assign({}, {left: 0},
      (_vm.headerHeight && { top: (_vm.headerHeight + "px") }),
      {transition: 'transform 0.5s'},
      (!_vm.isOnPined && {
        transform: ("translateY(calc(-100% + " + _vm.subTabSectionHeight + "px)"),
      }))),attrs:{"id":"head-room"}},[_c('section',[(_vm.headerTab && _vm.headerTab.length !== 1)?_c('section',{staticClass:"position-relative overflow-hidden bg-white"},[_c('header-tab')],1):_vm._e(),(_vm.title)?_c('section',{staticClass:"text-center white bg-img flex-center position-relative",style:({
          backgroundImage: ("url(" + (require('@/assets/img/common/banner.png'))),
          paddingBottom: _vm.calcHeight('7rem'),
        })},[_c('div',{staticClass:"cover w-100 h-100 position-absolute",style:({
            top: 0,
            left: 0,
            zIndex: 1,
            opacity: '0.7',
            backgroundColor: '#012965',
          })}),_c('div',{staticClass:"w-100 h-100 flex-center position-absolute",style:({
            top: 0,
            left: 0,
            zIndex: 2,
          })},[_c('h3',{staticClass:"fs-24 fw-7"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]):_vm._e()]),(_vm.subTab)?_c('section',{staticClass:"shadow bg-white",attrs:{"id":"sub-tab-section"}},[(_vm.$route.name === 'check-therapy')?_c('h4',{staticClass:"fw-7 fs-24 fs-md-30 text-center pt-4 pt-md-5"},[(_vm.id === 'music')?_c('span',[_vm._v("마음건강에 도움이 되는 음악")]):_c('span',[_vm._v("심리적 안정을 유도하는 ASMR")])]):_vm._e(),_c('sub-tab',{style:({ paddingTop: _vm.calcHeight('2.2rem') }),attrs:{"offset":_vm.gbHeight}})],1):_vm._e()]),_c('side-menu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }