var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mx-0 d-none d-md-flex border-bottom border-gray-5 py-3",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","sm":"5"}},[_c('span',{staticClass:"gray fw-7 fs-20"},[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"7"}},[_c('div',{staticClass:"flex-end"},[(_vm.loading)?_c('b-overlay',{staticClass:"d-flex",style:({
            borderRadius: '50%',
            overflow: 'hidden',
            width: _vm.calcHeight('3rem'),
            height: _vm.calcHeight('3rem'),
          }),attrs:{"show":_vm.loading,"rounded":"","spinner-small":"","spinner-variant":"green","opacity":"0","z-index":"5"}}):_vm._e(),(_vm.isTimeLine)?_c('div',{staticClass:"flex-center"},[_c('span',{staticClass:"gray-5"},[_vm._v(_vm._s(_vm.timeConvert(_vm.currentTime)))]),_c('div',{staticClass:"ml-3 d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentTime),expression:"currentTime"}],staticClass:"range",attrs:{"type":"range","max":_vm.duration},domProps:{"value":(_vm.currentTime)},on:{"input":_vm.handleInput,"__r":function($event){_vm.currentTime=$event.target.value}}})]),_c('span',{staticClass:"gray fw-7 ml-3"},[_vm._v(_vm._s(_vm.timeConvert(_vm.duration)))])]):_vm._e(),_c('div',{staticClass:"ml-4 d-flex"},[_c('b-btn',{ref:"handle-btn",staticClass:"bg-img",style:({
              width: _vm.calcHeight('3rem'),
              paddingBottom: _vm.calcHeight('3rem'),
              backgroundImage: !_vm.isPlay
                ? ("url(" + (require('@/assets/img/common/ic-play-green.svg')) + ")")
                : ("url(" + (require('@/assets/img/common/ic-stop-green.svg')) + ")"),
            }),attrs:{"variant":"link"},on:{"click":function($event){!_vm.isPlay ? _vm.play() : _vm.pause()}}})],1)],1)])],1),_c('b-row',{staticClass:"mx-0 d-md-none border-bottom border-gray-5 py-3",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"gray fw-7 fs-20 flex-start"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.loading)?_c('b-overlay',{staticClass:"mb-1",style:({
            width: '2rem',
            height: '2rem',
          }),attrs:{"show":_vm.loading,"rounded":"","spinner-small":"","spinner-variant":"green","opacity":"0","z-index":"5"}}):_vm._e()],1),(_vm.isTimeLine)?_c('div',{staticClass:"flex-start mt-2"},[_c('span',{staticClass:"gray-5"},[_vm._v(_vm._s(_vm.timeConvert(_vm.currentTime)))]),_c('div',{staticClass:"ml-3 d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentTime),expression:"currentTime"}],staticClass:"range",attrs:{"type":"range","max":_vm.duration},domProps:{"value":(_vm.currentTime)},on:{"input":_vm.handleInput,"__r":function($event){_vm.currentTime=$event.target.value}}})]),_c('span',{staticClass:"gray fw-7 ml-3"},[_vm._v(_vm._s(_vm.timeConvert(_vm.duration)))])]):_vm._e()]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('b-btn',{ref:"handle-btn",staticClass:"bg-img",style:({
          width: _vm.calcHeight('3rem'),
          paddingBottom: _vm.calcHeight('3rem'),
          backgroundImage: !_vm.isPlay
            ? ("url(" + (require('@/assets/img/common/ic-play-green.svg')) + ")")
            : ("url(" + (require('@/assets/img/common/ic-stop-green.svg')) + ")"),
        }),attrs:{"variant":"link"},on:{"click":function($event){!_vm.isPlay ? _vm.play() : _vm.pause()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }