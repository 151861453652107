var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"hc-container"},[(_vm.report)?[(_vm.isHealthNews)?[_c('h3',{staticClass:"pb-4 border-bottom border-gray-5 mb-5 flex-start"},[(_vm.splitedTitle(_vm.report.title).column)?_c('span',{staticClass:"theme-btn px-2 mr-3 fs-18",style:({
            minWidth: _vm.calcHeight('6rem'),
          })},[_vm._v(_vm._s(_vm.splitedTitle(_vm.report.title).column))]):_vm._e(),_c('span',{staticClass:"fs-20 fs-md-24 fw-7 gray",domProps:{"innerHTML":_vm._s(
            _vm.splitedTitle(_vm.report.title)
              ? _vm.splitedTitle(_vm.report.title).title
              : _vm.report.title
          )}})]),_c('div',{staticClass:"p-2 p-md-4 mb-5 rounded-10",style:({ backgroundColor: '#fafafa' })},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"gray fs-19 news-detail-content",domProps:{"innerHTML":_vm._s(_vm.report.content)}})])])]:[_c('h3',{staticClass:"fs-24 fw-7 gray pb-5 border-bottom border-gray-5"},[_vm._v(" "+_vm._s(_vm.report.title)+" ")]),_c('div',{staticClass:"py-5 flex-center"},[_c('div',{staticClass:"bg-img new-img position-relative overflow-hidden",style:({
            backgroundImage: ("url(" + (require('@/assets/img/common/news-detail.svg')) + ")"),
            width: '50rem',
            paddingBottom: '28.125rem',
            borderRadius: '10px',
          })},[_c('div',{style:({
              height: '28.125rem',
            })},[_c('a',{staticClass:"white-line-btn fs-20",style:({
                position: 'absolute',
                left: '50%',
                top: '50%',
                zIndex: 5,
                transform: 'translate(-50%, -50%)',
              }),attrs:{"href":_vm.getNewsUrl,"target":"_blank","variant":"link"}},[_vm._v(" 기사 자세히 보기 ")]),_c('div',{staticClass:"fs-18 white text-center py-4",style:({
                position: 'absolute',
                left: '50%',
                bottom: '3%',
                zIndex: 5,
                transform: 'translateX(-50%)',
                width: '90%',
              })},[(_vm.isKidNews)?_c('p',{staticClass:"text-break"},[_vm._v("출처: "+_vm._s(_vm.report.content))]):_c('p',{staticClass:"text-break"},[_vm._v("출처: "+_vm._s(_vm.report.origin))]),_c('p',[_vm._v("기사발표일 : "+_vm._s(_vm.regdate))])])])])])],_c('div',{staticClass:"flex-center mb-5"},[_c('router-link',{staticClass:"blue-submit-btn px-5 fs-20 fs-md-16",attrs:{"to":{ name: 'info-news', key: _vm.key }}},[_vm._v("목록보기")])],1),_c('prev-next',{attrs:{"btnObj":_vm.btnObj}})]:[_c('skeleton-list-detail')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }