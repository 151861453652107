var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative w-100 table-slide"},[(_vm.isPopover)?_vm._t("popover",[_c('img',{class:[
        { 'popover-visiable': _vm.isAllowTouchMove && _vm.popoverVisiable },
        'position-absolute table-popover' ],style:({
        top: '-4rem',
        right: 0,
        zIndex: 10,
        width: _vm.calcHeight('7rem'),
      }),attrs:{"src":require("@/assets/img/common/ic-table-popover.svg"),"alt":"스크롤 안내 문구"}})]):_vm._e(),_c('swiper',{ref:"table-swiper",class:{ 'mt-5': _vm.isAllowTouchMove },style:({ whitespace: 'nowrap' }),attrs:{"options":_vm.setOption},on:{"touchEnd":_vm.touchEnd,"touchStart":_vm.touchStart}},[_c('swiper-slide',{style:(Object.assign({}, _vm.styles))},[_vm._t("content")],2)],1),(_vm.isNavi)?_vm._l((['next', 'prev']),function(btn){return _c('b-btn',{key:btn === 'next'
          ? ((_vm.setOption.spaceBetween) + "-prev-btn")
          : ((_vm.setOption.spaceBetween) + "-next-btn"),class:[
        btn === 'next' ? _vm.nextEl : _vm.prevEl,
        ("table-" + btn + "-el d-flex flex-column position-absolute h-85") ],attrs:{"slot":"button-next","variant":"link"},slot:"button-next"})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }