var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('div',{staticClass:"bg-white d-block pt-3 pt-md-4"},[_c('tab-swiper',{ref:"header-tab-swiper",staticClass:"tab",attrs:{"items":_vm.headerTab,"options":Object.assign({}, {navigation: {
          nextEl: '.header-tab-next',
          prevEl: '.header-tab-prev',
        }},
        _vm.setSpaceBetween,
        {breakpoints: _vm.breakpoints,
        initialSlide: _vm.activeSlideIndex})},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var tab = ref.tab;
      var idx = ref.idx;
return [_c('b-btn',{key:idx,class:[
          _vm.title === tab.text ? 'active' : '',
          'tab-item gray-3 fs-20 fs-md-18 pb-2' ],attrs:{"variant":"link"},on:{"click":function($event){$event.preventDefault();return _vm.handleClickSlide(tab)}}},[_vm._v(" "+_vm._s(tab.text)+" ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }