var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('swiper',{ref:"mungin-swiper",attrs:{"options":{
      allowTouchMove: false,
      spaceBetween: 100,
    }},on:{"slide-change":_vm.onSlideChange}},_vm._l((_vm.slideItems),function(item,i){return _c('swiper-slide',{key:((item.number) + "_" + (_vm.slideItems.length)),attrs:{"allow-touch-move":false}},[_c('w-form',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('div',[(item.questiontitle)?_c('h4',{staticClass:"fw-7 mb-2 mb-md-3 fs-24 flex-start align-items-start"},[(item.titleColumn)?_c('span',{staticClass:"theme-btn fs-18 mr-2 py-2 px-3"},[_vm._v(_vm._s(item.titleColumn))]):_c('span',{staticClass:"mr-1"},[_vm._v("Q.")]),_vm._v(" "+_vm._s(!_vm.appendTtile ? item.questiontitle : _vm.appendTtile + item.questiontitle)+" ")]):_vm._e()]),(item.answersType === 'checkbox')?_c('p',{staticClass:"red fs-18 mb-3"},[_vm._v(" 복수선택형 문항입니다. ")]):_vm._e(),(item.examples && item.examples.length)?_c('div',{staticClass:"mb-3 fs-20 fs-7 gray pl-3"},_vm._l((item.examples),function(example){return _c('p',{key:example.content},[_vm._v(" - "+_vm._s(example.content)+" ")])}),0):_vm._e(),_c('div',{staticClass:"flex-start mb-3"},[_c('b-btn',{directives:[{name:"show",rawName:"v-show",value:(i),expression:"i"}],staticClass:"gray-3 fs-20 fs-md-18 fw-7 flex-center",style:({ marginLeft: '-10px' }),attrs:{"slot":"button-prev","variant":"link"},on:{"click":_vm.slidePrev},slot:"button-prev"},[_c('img',{style:({ width: '2rem' }),attrs:{"src":require("@/assets/img/common/ic-gray-arrow.svg"),"alt":"이전 문항"}}),_vm._v("이전 문항")])],1),(item.questionImgs)?_c('b-row',{staticClass:"mx-n4 mx-sm-n5 mx-lg-n2 mb-4",style:({
            minHeight: '14.5rem',
          }),attrs:{"align-h":"center"}},_vm._l((item.questionImgs),function(img,i){return _c('b-col',{key:(img + "-" + i),staticClass:"px-4 px-md-5 px-lg-2 py-2",attrs:{"cols":item.questionImgs.length > 1 ? 6 : 12,"md":item.questionImgs.length > 1 ? 6 : 10,"lg":item.questionImgs.length > 1 ? 3 : 8,"align-self":"center"}},[_c('div',{staticClass:"d-flex justify-content-center"},[(item.questionImgs.length > 1)?_c('span',{staticClass:"flex-center border border-black rounded-circle mr-2",style:({ width: '1.4rem', height: '1.4rem' })},[_vm._v(" "+_vm._s(i + 1)+" ")]):_vm._e(),_c('img',{style:({
                  width: item.questionImgs.length > 1 ? '85%' : '100%',
                }),attrs:{"src":img,"alt":("그림 " + (i + 1))}})])])}),1):_vm._e(),(item.questiontitle !== '고객님의 정보를 입력해 주세요.')?_c('question-box',_vm._b({on:{"changeSlide":_vm.changeSlide},model:{value:(item.selectedAnswers),callback:function ($$v) {_vm.$set(item, "selectedAnswers", $$v)},expression:"item.selectedAnswers"}},'question-box',Object.assign({}, item,
            {answersType: item.answersType || 'radio'}),false)):_c('question-box-user-info',{model:{value:(_vm.useInfo),callback:function ($$v) {_vm.useInfo=$$v},expression:"useInfo"}}),_c('div',{staticClass:"flex-center"},[(i === _vm.slideItems.length - 1)?_c('b-btn',{staticClass:"blue-submit-btn mr-2 px-5 fs-20 mt-5",attrs:{"variant":"link","disabled":invalid || _vm.isEmpty},on:{"click":function($event){return _vm.$emit('handleSubmit', _vm.useInfo)}}},[_vm._v("결과보기")]):_vm._e(),_c('b-btn',{directives:[{name:"show",rawName:"v-show",value:(
              i !== _vm.slideItems.length - 1 &&
                ['checkbox', 'input'].includes(_vm.slideItems[i].answersType)
            ),expression:"\n              i !== slideItems.length - 1 &&\n                ['checkbox', 'input'].includes(slideItems[i].answersType)\n            "}],staticClass:"blue-submit-btn mr-2 px-5 fs-20 mt-5",attrs:{"variant":"link","disabled":invalid || _vm.isEmpty},on:{"click":_vm.slideNext}},[_vm._v("다음 문항 보기")])],1)]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }