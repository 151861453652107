var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-auto"},[_c('p',{staticClass:"py-5 fs-16"},[_vm._v(" 선택검사: 같은 알파벳 내에서 검사항목 한 개를 선택하여 받으실 수 있습니다. ")]),(_vm.hospitalItems.length)?_c('div',{style:({ minWidth: '500px' })},[_c('b-row',{staticClass:"mx-0"},_vm._l((_vm.tHead),function(head){return _c('b-col',{key:head,staticClass:"px-0 flex-center py-3 fs-18",style:({
          backgroundColor: '#f8f8f8',
          borderBottom: '1px solid #eee',
          borderTop: '1px solid #eee',
        })},[_vm._v(" "+_vm._s(head)+" ")])}),1),_vm._l((_vm.tBody),function(body,i){return _c('b-row',{key:i,staticClass:"mx-0",style:({
        borderBottom: '1px solid #eee',
      })},[_c('b-col',{staticClass:"p-2 pl-3",style:({
          borderRight: '1px solid #eee',
        })},[_vm._v(" "+_vm._s(body.sname)+" ")]),_vm._l((body.hosArr),function(hos,i){return _c('b-col',{key:i,staticClass:"flex-center",style:(Object.assign({}, (body.hosArr.length !== i + 1 && {
            borderRight: '1px solid #eee',
          })))},[_c('i',{class:[hos.isYN === 'N' ? 'icon-cancel-2' : 'icon-circle-empty']})])})],2)})],2):_c('div',{style:({ minWidth: '500px' })},[_c('b-row',{staticClass:"mx-0"},_vm._l((_vm.$route.query.codes.length + 1),function(i){return _c('b-col',{key:i,staticClass:"px-0 flex-center py-3",style:({
          backgroundColor: '#f8f8f8',
          borderBottom: '1px solid #eee',
          borderTop: '1px solid #eee',
        })},[_c('b-skeleton',{attrs:{"animation":null,"width":"30%","height":"2rem"}})],1)}),1),_vm._l((15),function(i){return _c('b-row',{key:i,staticClass:"mx-0",style:({
        borderBottom: '1px solid #eee',
      })},[_c('b-col',{staticClass:"p-2 pl-3 flex-center",style:({
          borderRight: '1px solid #eee',
        })},[_c('b-skeleton',{attrs:{"animation":null,"width":"20%"}})],1),_vm._l((_vm.$route.query.codes),function(hos,i){return _c('b-col',{key:i,staticClass:"flex-center",style:(Object.assign({}, (_vm.$route.query.codes.length !== i + 1 && {
            borderRight: '1px solid #eee',
          })))},[_c('b-skeleton',{attrs:{"animation":null,"width":"20%"}})],1)})],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }