var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hospitals.length)?[_c('b-row',{ref:"compare-text",staticClass:"mt-5",attrs:{"align-h":"center"}},[_c('b-col',[_c('div',{staticClass:"fs-20 fw-7 flex-start flex-wrap mb-3 mb-md-2"},[_vm._v(" 최대 3개의 관심 병원을 선택 "),_c('span',{staticClass:"bg-img d-inline-block mx-2",style:({
              width: _vm.calcHeight('1.5rem'),
              paddingBottom: _vm.calcHeight('1.5rem'),
              backgroundImage: ("url(" + (require('@/assets/img/common/ic-checked-green.svg')) + ")"),
            })}),_vm._v(" 하고 비교해 보세요. ")]),_c('b-btn',{attrs:{"variant":"link fs-18 flex-start gray-11"},on:{"click":_vm.resetSelected}},[_c('img',{staticClass:"mr-2",style:({
              width: _vm.calcHeight('1.5rem'),
            }),attrs:{"src":require("@/assets/img/common/ic-remove-check.svg"),"alt":"선택해제하기"}}),_c('span',{staticClass:"border-bottom border-gray-11"},[_vm._v(" 선택 해제 하기 ")])])],1),_c('b-col',{staticClass:"text-center text-lg-right mt-4 mt-lg-0",attrs:{"cols":"12","lg":"3"}},[_c('b-btn',{staticClass:"green-btn py-2 px-4 fs-18 fw-7",attrs:{"variant":"link"},on:{"click":_vm.compareHos}},[_vm._v(" 병원 비교하기 ")])],1)],1),_vm._l((_vm.hospitals),function(hos,idx){return [_c('div',{key:idx,attrs:{"id":("modal-tab-" + idx)}},[_c('div',{staticClass:"border-bottom border-3 border-black pt-4 pt-lg-5 pb-3"},[_c('hash-title',{attrs:{"title":hos.region}})],1),_c('ul',{staticClass:"mb-3"},_vm._l((hos.items),function(row,_idx){return _c('li',{key:_idx,staticClass:"border-bottom border-gray-5"},[_c('div',{staticClass:"flex-between align-items-start py-4 px-2"},[_c('div',{staticClass:"flex-start"},[_c('b-checkbox',{staticClass:"mr-3",attrs:{"name":"check-button","button":"","button-variant":"link"},model:{value:(_vm.selectedCode[row.hpt_code]),callback:function ($$v) {_vm.$set(_vm.selectedCode, row.hpt_code, $$v)},expression:"selectedCode[row.hpt_code]"}},[_c('div',{staticClass:"flex-start align-items-start"},[_c('img',{staticClass:"mx-2 mx-sm-3 mt-1",style:({
                        width: _vm.calcHeight('1.5rem'),
                      }),attrs:{"src":_vm.selectedCode[row.hpt_code]
                          ? ("" + (require('@/assets/img/common/ic-checked-green.svg')))
                          : ("" + (require('@/assets/img/common/ic-check-box.svg'))),"alt":"check box"}}),_c('div',{staticClass:"d-flex align-items-start flex-column"},[_c('span',{staticClass:"fw-7 fs-20 black"},[_vm._v(_vm._s(row.h_name))]),_c('span',{staticClass:"gray-5 fs-18 fs-md-16 text-left"},[_vm._v(_vm._s(row.h_adress1)+" "+_vm._s(row.h_adress2))])])])])],1),_c('div',[_c('b-btn',{staticClass:"flex-center",attrs:{"variant":"link"},on:{"click":function($event){return _vm.callDetail({
                      hsptl_sn: row.hsptl_sn,
                      hpt_code: row.hpt_code,
                    })}}},[_c('img',{style:({
                      width: _vm.calcHeight('2.375rem'),
                    }),attrs:{"src":("" + (require('@/assets/img/common/ic-zoom-in.svg'))),"alt":"zoom"}})])],1)]),_c('div',{staticClass:"p-3 p-sm-4 fs-18",style:({ backgroundColor: '#ededed', color: '#707070' })},[_c('p',[_vm._v(" "+_vm._s(row.me_note ? row.me_note : "-")+" ")])])])}),0)])]}),_c('div',{staticStyle:{"height":"20vh"}})]:[_c('skeleton-modal-list')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }