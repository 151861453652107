var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-swiper',{ref:"table-swiper",attrs:{"options":{
      slidesPerView: 1,
      allowTouchMove: true,
      slidesPerView: 0.4,
      breakpoints: {
        400: {
          slidesPerView: 0.15,
        },
        720: {
          slidesPerView: 0.2,
        },
        992: {
          slidesPerView: 0.3,
        },
      },
      navigation: {
        nextEl: '.table-next',
        prevEl: '.table-prev',
      },
    },"isPopover":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-table-simple',{staticClass:"bg-white",style:({ whiteSpace: 'pre-wrap' }),attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.fields),function(head){return _c('b-th',{key:head.key,staticClass:"bg-gray-4 white fs-20 fs-md-18",style:(Object.assign({}, head.thStyle)),attrs:{"colspan":head.colspan}},[_vm._v(_vm._s(head.label))])}),1)],1),_c('b-tbody',_vm._l((_vm.mappedTable),function(row,i){return _c('b-tr',{key:i},_vm._l((row),function(td,key){return _c('b-td',{key:("b-td-" + key),class:[
                {
                  'bg-gray-10': key === 'target',
                },
                key === 'division' ? 'bg-gray-4 white text-center' : '',
                'align-middle fs-20 fs-md-18 p-2' ],style:(Object.assign({}, (td &&
                  td.degree && {
                    backgroundColor: 'var(--green-4)',
                  }),
                (td &&
                  td.bgColor === '#FFAFD5' && { backgroundColor: 'pink' }),
                (td && td.tdStyle),
                (!_vm.isExplanation && { cursor: 'pointer' }))),attrs:{"rowspan":td && td.rowspan,"colspan":td && td.colspan},on:{"click":function($event){return _vm.handleClick(td && td.colName)}}},[(td && td.label)?_c('span',[_vm._v(_vm._s(td.label))]):(td && td.degree)?_c('span',[_vm._v(_vm._s(td.degree)),_c('br'),_vm._v(_vm._s(td.dayText))]):_vm._e()])}),1)}),1)],1),(_vm.isExplanation)?_c('div',[_c('ul',{staticClass:"ml-4"},[_c('li',[_vm._v("읽어보세요")]),_c('li',[_c('span',{staticClass:"green"},[_vm._v("국가필수예방접종")]),_vm._v(" 국가가 권장하는 예방접종(국가는 감염병의 예방 및 관리에 관한 법률을 통해 예방접종 대상 감염병과 예방접종의 실시기준 및 방법을 정하고, 국민과 의료제공자에게 이를 준수토록 하고 있음) ")]),_c('li',[_c('span',{staticClass:"green"},[_vm._v("기타예방접종")]),_vm._v(" 국가필수예방접종 이외 민간 의료기관에서 접종 가능한 예방접종 ")]),_c('li',[_vm._v("기초접종 단기간 내에 방어면역 형성을 위해 시행하는 접종")]),_c('li',[_vm._v(" 추가접종 기초접종 후 형성된 방어면역을 장기간 유지하기 위해 기초접종 후 일정기간이 지난 다음 추가로 시행하는 접종 ")])])]):_vm._e()]},proxy:true}])}),_c('modal-vaccination-registration',{attrs:{"idx":_vm.selectedIdx,"colName":_vm.selectedColName},on:{"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }